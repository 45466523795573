import React, { FC } from "react";
import Section from "../../components/section";
import { PageHero } from "../../components/page-hero";
import Layout from "../../components/layout/layout";
import availability_1 from "../../images/monitoringScreens/availability_1.jpg";
import availability_2 from "../../images/monitoringScreens/availability_2.jpg";
import availability_3 from "../../images/monitoringScreens/availability_3.jpg";
import availability_4 from "../../images/monitoringScreens/availability_4.jpg";

import useCalculateHeightHook from "../../hooks/useCalculateHeightHook";
import "./styles.scss";

const AvailabilityMonitoring: FC = () => {
  const [size, videoLength] = useCalculateHeightHook();

  return (
    <div className="content-wrapper">
      <PageHero
        title="Availability monitoring"
        subtitle="Monitor any URL for downtime, and get alerts when a web page goes down."
      />
      <Layout>
        <Section>
          <div className="col-lg-12 availability-monitoring">
            <div className="go-back">
              <a className="learn_btn_two" href="/how-to-use-hexowatch">
                <svg
                  width="40px"
                  height="20px"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="long-arrow-alt-left"
                  className="svg-inline--fa fa-long-arrow-alt-left fa-w-14 icon"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"
                  ></path>
                </svg>
                Back to how to use Hexowatch
              </a>
            </div>

            {size ? (
              <video
                controls
                preload="none"
                poster={availability_1}
                ref={videoLength}
                style={{ height: size ? size : "auto" }}
              >
                <source
                  src={
                    "https://storage.googleapis.com/website.hexometer.com/Availability_monitor_-_Walkthrough_1598174160.mp4"
                  }
                  type="video/mp4"
                />
              </video>
            ) : (
              <video
                controls
                preload="none"
                poster={availability_1}
                ref={videoLength}
                style={{ visibility: "hidden" }}
              >
                <source
                  src={
                    "https://storage.googleapis.com/website.hexometer.com/Availability_monitor_-_Walkthrough_1598174160.mp4"
                  }
                  type="video/mp4"
                />
              </video>
            )}

            <h2>Availability monitor - Walkthrough</h2>
            <br />
            <div className="img-block">
              <img
                src={availability_1}
                alt="Select availability monitoring tool"
              />
            </div>
            <p className="mb">
              The availability monitor is great for keeping an eye on any page
              for downtime. This tactical monitor is useful to monitor key pages
              on your own websites, clients, prospects or affiliates.
            </p>

            <div className="img-block">
              <img src={availability_2} alt="Preview desired URL" />
            </div>
            <p className="mb">
              To get started enter the URL you want to check and click preview.
            </p>

            <div className="img-block">
              <img
                src={availability_3}
                alt="Choose the frequency of the checks, your preferred notification channels"
              />
            </div>
            <p className="mb">
              Once the page has loaded you can choose the frequency of the
              checks, your preferred notification channels, as well as set
              custom start and end dates.
            </p>

            <div className="img-block">
              <img
                src={availability_4}
                alt="Click start monitoring to get started"
              />
            </div>
            <p className="mb">
              Then click start monitoring to get started. Hexowatch will keep an
              eye on the page and will notify you if it becomes unavailable.
            </p>
          </div>
        </Section>
      </Layout>
    </div>
  );
};

export default AvailabilityMonitoring;
